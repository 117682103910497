import React from "react";

function Contact() {
  return (
    <>
      <p>Hello world Contact</p>
    </>
  );
}

export default Contact;